import React, { FC } from "react";
import { PageProps } from "gatsby";
import { JsonLd } from "gatsby-plugin-next-seo";
import { ExternalLink, ExternalLinkGTag } from "../components/Link";
import tw, { styled, TwStyle } from "twin.macro";

import Layout from "../components/Layout";
import Seo from "../components/Seo";

import ldMichaelLocherCH from "../ld/michaelLocherCH.json";
import ldMichaelLocher from "../ld/michaelLocher.json";
import ldTopOne from "../ld/topOne.json";
import ldSeason from "../ld/season21.json";

import { determineNextOver, NextOverProps } from "../model/pageHelpers";
import { SeasonInfo } from "../../../linkeddata/page";
import { LessonStatus } from "../../../linkeddata/season";
import pageData from "./season21.json";
const season: SeasonInfo = pageData as any;

const StruturedData = () => (
  <>
    <JsonLd json={ldMichaelLocherCH as any} />
    <JsonLd json={ldMichaelLocher as any} />
    <JsonLd json={ldTopOne as any} />
    <JsonLd json={ldSeason as any} />
  </>
);

interface DateItemProps extends NextOverProps {
  status: LessonStatus;
}

const DateItem: FC<WithChildren<DateItemProps>> = styled.li(
  ({ next, over, status }: DateItemProps) => [
    over && status !== "Cancelled" && tw`text-gray-500`,
    over && status === "Cancelled" && tw`text-red-300`,
    !over && status === "Cancelled" && tw`text-red-700`,
    next && tw`font-bold`,
  ]
);

const CHF = ({ children }: WithChildren) => <span>CHF {children}</span>;

const PriceTable = () => {
  const lessImportantStyle: TwStyle = tw`text-sm italic`;

  interface PriceRowProps extends NextOverProps {
    i: number;
    n: number;
    price: string;
    pricePerLesson: string;
  }
  const PriceRow = ({
    i,
    n,
    price,
    pricePerLesson,
    next,
    over,
  }: PriceRowProps) => (
    <tr
      key={`${i}_${n}`}
      css={[over && tw`text-gray-500`, next && tw`font-bold`]}
    >
      <td css={lessImportantStyle}>
        <span>vor {i}. Lektion</span>
      </td>
      <td tw="font-medium">
        <CHF>{price}</CHF>
      </td>
      <td>{n + 1 - i}</td>
      <td css={lessImportantStyle}>
        <CHF>{pricePerLesson}</CHF>
      </td>
    </tr>
  );

  const bundlesNextOver: NextOverProps[] = determineNextOver(
    season.bundles,
    (bundle) => bundle.lessons[0].end
  );

  return (
    <table tw="mb-4 text-center align-baseline">
      <caption tw="hidden">Preis des Staffel-Pass</caption>
      <thead>
        <tr>
          <th css={lessImportantStyle}>Kaufzeitpunkt</th>
          <th>Preis</th>
          <th>Lektionen</th>
          <th css={lessImportantStyle}>Preis/Lektion</th>
        </tr>
      </thead>
      <tbody>
        {season.bundles.map((bundle, idx) => {
          return (
            <PriceRow
              {...bundlesNextOver[idx]}
              key={bundle.uuid}
              i={1 + idx}
              n={season.bundles.length}
              price={bundle.price.amountTrim}
              pricePerLesson={bundle.pricePerLesson.amount}
            />
          );
        })}
      </tbody>
    </table>
  );
};

type KeyValueProps = WithChildren<{ dt: string }>;
const KeyValue = ({ dt, children }: KeyValueProps) => (
  <>
    <dt tw="text-gray-700 italic">{dt}</dt>
    <dd tw="mb-4 md:ml-4">{children}</dd>
  </>
);

////////////////////////////////////////////////////////////////////////////////

const Heading: FC = () => (
  <h1 tw="text-center md:text-left text-3xl my-3 md:my-4 font-bold md:font-extrabold sm:tracking-wide md:tracking-wider">
    Step Aerobic in Bern
  </h1>
);

const Spacer: FC = tw.hr`my-2 border border-gray-900`;

const WhatIsStep: FC = () => (
  <div tw="my-4 p-2 bg-snow border rounded-md border-solid border-gray-700">
    <aside>
      <p tw="m-0 font-light">
        Step Aerobic ist ein Herz-Kreislauf Training bei dem auch Beine und Po
        optimal trainiert werden.
        <br />
        Durch die höhenverstellbare Plattform, kann sich jede Teilnehmer:in die
        Belastung individuell einstellen.
        <br />
        Zu mitreissender Musik bewegen wir uns auf und rund um die Plattform und
        verbrennen dabei nebenher hunderte von Kalorien.
      </p>
    </aside>
  </div>
);

/*
const News: FC = () => (
  <div tw="my-4 p-2 bg-blue-50 border rounded-md border-solid border-gray-100">
    <aside>
      <p tw="m-0 text-center md:text-left">
        <span tw="w-full block md:inline md:w-auto font-bold sm:tracking-wide md:tracking-wider">
          Info
        </span>{" "}
        Die{" "}
        <InternalLink to="/step-aerobic-season6" tw="italic">
          Staffel 6: Herbst 2022 reloaded
        </InternalLink>{" "}
        startet bereits im Oktober 2022.
      </p>
    </aside>
  </div>
);
*/

const PriceInfo: FC<WithChildren> = ({ children }) => (
  <div tw="px-4 py-2 bg-snow rounded-2xl">
    <dl>
      <KeyValue dt="Preis">{children}</KeyValue>
    </dl>
  </div>
);

const SeasonSectionHeader: FC<WithChildren> = tw.h3`text-lg mb-2 mt-3 md:mt-4 font-bold`;

const Season: FC = () => {
  const lessonsNextOver: NextOverProps[] = determineNextOver(
    season.lessons,
    (lesson) => lesson.end
  );
  return (
    <section>
      <h2 tw="text-center md:text-left text-2xl my-3 md:my-4 font-bold sm:tracking-wide md:tracking-wider">
        {season.title}
        {season.subtitle && (
          <>
            : <span tw="text-xl">{season.subtitle}</span>
          </>
        )}
      </h2>
      <Spacer />

      <section>
        <SeasonSectionHeader>Details</SeasonSectionHeader>
        <dl>
          <KeyValue dt="Was">Step Aerobic in Bern mit Michael Locher</KeyValue>
          <KeyValue dt="Wann">{season.time}</KeyValue>
          <KeyValue dt="Wo">
            <span>
              <ExternalLinkGTag href="https://www.top-one.ch/">
                Top-One
              </ExternalLinkGTag>
              {` `}[
              <ExternalLinkGTag href="https://maps.google.com/maps?ll=46.936377,7.435766&z=18&t=m&cid=10470311257124978915">
                Morillonstrasse 8, 3007 Bern
              </ExternalLinkGTag>
              ]
            </span>
          </KeyValue>
        </dl>

        <SeasonSectionHeader>Termine</SeasonSectionHeader>
        <StruturedData />
        <p>
          Die <em>Staffel {season.nr}</em> besteht aus folgenden{" "}
          {season.lessons.length} Lektionen:
        </p>
        <ul tw="list-disc mt-1 mb-4 ml-4 pl-4">
          {season.lessons.map((lesson, idx) => {
            const nextOver = lessonsNextOver[idx];
            return (
              <DateItem key={lesson.uuid} status={lesson.status} {...nextOver}>
                {lesson.start.longDateWithWeekday}
                {lesson.status === "Cancelled" &&
                  ((nextOver.over && " ausgefallen") || " Ausfall!")}
              </DateItem>
            );
          })}
        </ul>
        <p>
          Die <em>Staffel {season.nr}</em> beginnt mit der ersten Lektion am{" "}
          {season.lessons[0].start.longDate} und endet mit der letzten Lektion
          am {season.lessons[season.lessons.length - 1].start.longDate}.
        </p>
      </section>

      <section>
        <section>
          <SeasonSectionHeader>Staffel-Pass</SeasonSectionHeader>
          <p>Der Staffel-Pass …</p>
          <ul tw="list-disc mt-1 mb-4 ml-4 pl-4">
            <li>
              enthält alle Lektionen vom Kaufzeitpunkt bis zum Staffelende
            </li>
            <li>kann jederzeit gekauft werden</li>
            <li>ist umso preiswerter, je früher gekauft</li>
          </ul>
          <PriceInfo>
            <PriceTable />
          </PriceInfo>
        </section>
        <section>
          <SeasonSectionHeader>Einzeleintritt</SeasonSectionHeader>
          <PriceInfo>
            <CHF>{season.singlePrice.amountTrim}</CHF>
          </PriceInfo>
        </section>
      </section>

      <section>
        <SeasonSectionHeader>Anmeldung &amp; Fragen</SeasonSectionHeader>
        <p tw="mb-4">
          Anmeldung, Feedback und Fragen zum Angebot bitte direkt zu mir:
        </p>
        <dl>
          <KeyValue dt="EMail">
            <ExternalLink
              href={`mailto:step@michael-locher.ch?subject=Step%20Staffel%20${season.nr}`}
            >
              step@michael-locher.ch
            </ExternalLink>
          </KeyValue>
          <KeyValue dt="Telegram">
            <ExternalLink href="https://t.me/michael_locher">
              @michael_locher
            </ExternalLink>
          </KeyValue>
          <KeyValue dt="Follow">
            <span>
              <ExternalLink
                rel="me"
                href="https://www.whatsapp.com/channel/0029Va9WwRxDp2Q8AKTGfQ0u"
              >
                WhatsApp Channel
              </ExternalLink>
              |
              <ExternalLink rel="me" href="https://bsky.app/profile/cmbntr.ch">
                Bluesky
              </ExternalLink>
              |
              <ExternalLink rel="me" href="https://mas.to/@cmbntr">
                Mastadon
              </ExternalLink>
              |
              <ExternalLink rel="me" href="https://twitter.com/cmbntr">
                Twitter
              </ExternalLink>
            </span>
          </KeyValue>
        </dl>
      </section>
    </section>
  );
};

const IndexPage: FC<PageProps> = () => {
  return (
    <Layout tw="flex flex-col mx-4 my-4 md:mx-8 lg:my-8 lg:mx-16 text-gray-900 font-sans bg-white">
      <Seo
        title="Michael Locher - Step Aerobic"
        description="Aktuelle Step Aerobic Angebote mit Michael Locher in Bern"
        canonical="https://www.michael-locher.ch/step-aerobic/"
      />
      <Heading />
      <WhatIsStep />
      <Season />
    </Layout>
  );
};

export default IndexPage;
